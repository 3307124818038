import React from 'react'
import { Link } from 'gatsby'

export default ({ article }) => (
  <li key={article.id}>
    <h3>
      <Link to={`/writing/${article.slug}`}>{article.title}</Link>
    </h3>
    <p><small>{article.date}</small></p>
  </li>
)
