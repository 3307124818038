import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleTeaser from "../components/article-teaser"

const WritingPage = ({ data }) => (
  <Layout>
    <SEO title="Writing" keywords={[`blog`, `articles`, `writing`]} />
    <section className="intro">
      <div className="intro-container">
        <h2>Writing</h2>
        <p>Writing about various topics in the <strong>kink</strong>, <strong>fetish</strong>, <strong>BDSM</strong>, and <strong>latex fetish</strong> communities and the <strong>Asian</strong> experience in kink and fetish.</p>
      </div>
    </section>
    <section className="content">
      <div className="content-container">
        <ul className="content-list">
          {data.allContentfulArticle.edges.map(({ node }) => (
            <ArticleTeaser article={node} />
          ))}
        </ul>
        <p><Link to="/" className="call-to-action">Go back home</Link></p>
      </div>
    </section>
  </Layout>
)

export const query = graphql`
  query {
    allContentfulArticle(sort: { fields: [date], order: DESC }) {
      edges {
        node {
          title
          slug
          date(formatString: "MMMM D, YYYY")
          body {
            json
          }
        }
      }
    }
  }
`

export default WritingPage
